import React from "react"
import styles from "../styles/index.module.css"
import { Helmet } from "react-helmet"
import Bar from "../components/bar.js"
import Footer from "../components/footer"
import "../index.css"
import Navbar from "../components/navbar"
import {
  IconButton,
  Grid,
  Typography,
  Button,
  useTheme,
  useMediaQuery,
} from "@material-ui/core"
import { ContactMailOutlined, Mail } from "@material-ui/icons"
export default function Home() {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up("md"))
  React.useEffect(() => {
    console.log("made with ❤️ by GreenSocks")
  })
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Philipp Schulz Consulting</title>
        <meta name="robots" content="index, follow" />
      </Helmet>
      <div>
        <Navbar />
        <section id="middle">
          <div className={styles.profile}>
            <Grid
              container
              alignItems="center"
              justify="space-between"
              spacing={9}
            >
              <Grid item xs={12} md={6}>
                <div className={styles.portraitContainer}>
                  <img
                    src="https://firebasestorage.googleapis.com/v0/b/ps-c-b11af.appspot.com/o/025_Schulz_sub%20(2).jpg?alt=media&token=6664d7c8-aa50-412d-b34d-1f5fbe117b22"
                    className={styles.portrait}
                    alt="portrait"
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  className={styles.texts2gold}
                  variant="body2"
                  gutterBottom
                >
                  PHILIPP SCHULZ {<br />} Leopold-Kronsteiner-Strasse 8 {<br />}
                  4060 Leonding{<br />}
                  {<br />}Email: {" office@ps-c.at"}
                  {<br />}
                  {<br />}Telefon: +43 660 6888 187
                  {<br />}
                </Typography>
                <Grid
                  container
                  justify={matches ? "flex-start" : "center"}
                  alignItems="center"
                ></Grid>
              </Grid>
            </Grid>
          </div>
        </section>

        <Footer />
      </div>
    </>
  )
}
